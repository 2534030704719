import React from 'react'
import HeroBanner from '../components/Hero'


const hero_settings = {
  size: 'is-medium',
  particle: false,
  images: '',
  css: '#ffafbd,#ffc3a0~#2193b0,#6dd5ed',
  font_color: '#fff'
}

const NotFoundPage = () => (
  <div>
    <HeroBanner
      title='404: NOT FOUND'
      subtitle="You just hit a route that doesn't exist... the sadness."
      hero_settings={hero_settings}
    />
  </div>
)

export default NotFoundPage
